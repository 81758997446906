import React from "react"

export const ListIcon = ({ active, ...props }) => (
  <svg
    width="25px"
    height="23px"
    viewBox="0 0 25 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Row</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Desktop-+-Mobile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="A11_Desktop-Roster-Grid"
        transform="translate(-1299.000000, -235.000000)"
        stroke="#959595"
      >
        <g id="Toggle" transform="translate(1264.000000, 235.000000)">
          <g id="Row" transform="translate(35.000000, 0.000000)">
            <path d="M0,0.5 L25,0.5" id="Stroke-1" />
            <path d="M0,11.5 L25,11.5" id="Stroke-3" />
            <path d="M0,23.5 L25,23.5" id="Stroke-5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
