import React from "react"

export const GridIcon = ({ active, ...props }) => {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Tile</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Desktop-+-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="A11_Desktop-Roster-Grid"
          transform="translate(-1264.000000, -235.000000)"
          fill="#959595"
        >
          <g id="Toggle" transform="translate(1264.000000, 235.000000)">
            <g id="Tile">
              <rect id="Rectangle" x="13" y="0" width="10" height="10" />
              <rect id="Rectangle-Copy-7" x="0" y="0" width="10" height="10" />
              <rect
                id="Rectangle-Copy-6"
                x="13"
                y="13"
                width="10"
                height="10"
              />
              <rect id="Rectangle-Copy-8" x="0" y="13" width="10" height="10" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
