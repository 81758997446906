import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { graphql, Link } from "gatsby"
import { flatten } from "lodash"
import Img from "gatsby-image"

import { Layout, GridContainer } from "../components/common"
import { GridIcon, ListIcon } from "../components/roster"
import SEO from "../components/common/SEO"
import media from "../styles/media"
import { isNotPlaceholder } from "../utils/common/content"
import { singularize } from "../utils/common/transforms"

import {
  useRosterCategoryState,
  useRosterCategoryDispatch,
  rosterCategoryActions,
} from "../components/context/RosterCategoryContext"

const RosterWrapper = styled(GridContainer)`
  padding-top: 40px;

  ${media.tablet`
    padding-top: 60px;
  `}
`

const IconWrapper = styled.div`
  display: none;

  ${media.tablet` 
    display: flex;
    justify-content: flex-end;
    grid-column: 12 / span 1;
  `}
`

const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    svg {
      g,
      path {
        fill: ${props => props.theme.red};
      }
    }
  }
`

const ListView = styled.div`
  grid-column: 1 / -1;
  display: none;

  ${media.tablet`
    display: ${props => (props.show ? "flex" : "none")};
    flex-direction: column;
    padding-top: 0px;
    grid-column: 1 / span 12;
    margin-bottom: 194px;
    font-size: 45px;
    font-family: StanleyRegular, serif;
    letter-spacing: 0;
    line-height: 52px;
  
    div, p {
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
    }
    
    
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${props => props.theme.red};
      }
    }
  `}
`

const GridView = styled.div`
  max-width: 100vw;
  font-size: 16px;
  grid-column: 1 / span 4;
  margin-bottom: 30px;

  ${media.tablet`
    display: ${props => (props.show ? "grid" : "none")};
    grid-column: 1 / span 12;
  `}
`

const GridBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;

  @supports (display: grid) {
    min-width: 100%;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    ${media.tablet`
      grid-column: 1 /span 12;
      grid-template-columns: repeat(6, 1fr);
    `}
  }

  p {
    margin: 0 auto;
  }

  div {
    margin-top: 16px;
  }

  h4,
  p {
    display: inline-block;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`
const GridBox = styled.div`
  padding: 0 10px 20px 10px;

  @supports (display: grid) {
    width: 100%;
    height: 100%;
  }

  div {
    margin-top: 5px;
  }

  flex: 1 1 45%;
  justify-content: flex-start;

  ${media.tablet`
    flex: 1 1 15%;  
  `}
`

const GridImage = styled(Img)`
  font-family: "object-fit: cover; object-position: center center;"; //ie polyfill

  @media (max-width: 975px) and (min-width: 768px) {
    max-height: 120px;
  }
`

const GridContent = styled.div`
  display: inline;
`

const GridTitleStyle = styled.h4`
  margin: 0 auto;
  padding: 0 auto;
  font-size: 16px;
  font-weight: bold;

  span {
    font-weight: normal;
  }
  ${media.tablet`
    padding-top: 5px;
  `}
`

const StyledGridIcon = styled(GridIcon)`
  ${media.tablet`
    margin-right: 12px;

    ${props =>
      props.active &&
      css`
        g > g {
          fill: ${props.theme.red};
        }
      `}
  `}
`

const StyledListIcon = styled(ListIcon)`
  &:hover {
    g > g {
      stroke: ${props => props.theme.red};
    }
  }

  ${media.tablet`
    ${props =>
      props.active &&
      css`
        g > g {
          stroke: ${props.theme.red};
        }
      `}
  `}
`

// a fix for orphaned elements at the end of the flex grid for ie11
// it fills the row with blanks so the last items do not try and
// fill the space themselves and become huge
const flexFix = sortedArtists => {
  const arr = []

  for (let i = 0; i < 6 - (sortedArtists.length % 6); i++) {
    arr.push(<GridBox aria-hidden="true" />)
  }
  return arr
}

const Roster = ({ data, location }) => {
  const [paramsParsed, setParamsParsed] = useState(false)
  const dispatch = useRosterCategoryDispatch()

  // Dispatch the initial values for selected category/subcategory based on the `location` prop
  // passed into this page component
  useEffect(() => {
    const params =
      typeof window !== undefined && new URLSearchParams(location.search)

    const categoryParam = params.get("category") || null
    const subcategoryParam = params.get("subcategory")

    dispatch({
      type: rosterCategoryActions.INIT_FROM_URL,
      payload: { category: categoryParam, subcategory: subcategoryParam },
    })

    setParamsParsed(true)
  }, [dispatch, location.search])

  const { selectedCategory, selectedSubcategory } = useRosterCategoryState()

  const [showGridView, setShowGridView] = useState(true)
  const [showListView, setShowListView] = useState(false)

  if (!paramsParsed) {
    return (
      <Layout headerTheme="dark" useRosterFiltering>
        <SEO title="Columbia Artists - Roster" path="/roster" />
      </Layout>
    )
  }

  const { publicCategories } = data.allContentfulGlobal.edges[0].node

  // The set of artists that we show on the page includes
  //   If "all" is selected
  //     - All artists
  //   If we have a selected category:
  //    - Artists whose primary category is the selected category
  //    - Artists whose parent category is the selected category
  //    - Artists whose category matches the selected subcategory
  const artists =
    !selectedCategory || selectedCategory === "all"
      ? data.allContentfulArtist.edges.filter(isNotPlaceholder)
      : data.allContentfulArtist.edges
          .filter(isNotPlaceholder)
          .filter(({ node }) => {
            if (selectedSubcategory) {
              return node.categories.some(
                category => selectedSubcategory === category.name
              )
            }

            return node.categories.some(
              category =>
                category.name === selectedCategory ||
                (category.parentCategory &&
                  category.parentCategory.some(
                    ({ name }) => name === selectedCategory
                  ))
            )
          })

  // Determine the artist's corresponding record in `publicCategories` so that we can handle sorting
  // the artist based on the configurable order in Contentful below
  artists.forEach(({ node }) => {
    let categoryRecord = publicCategories.find(
      c => c.name === node.categories[0].name
    )

    if (!categoryRecord) {
      categoryRecord = flatten(publicCategories.map(c => c.subcategories)).find(
        s => s.name === node.categories[0].name
      )
    }

    node._categoryRecord = categoryRecord
  })

  const sortedArtists = [...artists].sort((first, second) => {
    /* // Remove category weights so sorting is by sortKey and last name only
    const firstCategoryRecord = first.node._categoryRecord
    const secondCategoryRecord = second.node._categoryRecord

    // First, find top level category (either self or parent) and use that as baseline weighting number
    const firstParent = firstCategoryRecord.parentCategory
      ? firstCategoryRecord.parentCategory[0]
      : firstCategoryRecord
    const secondParent = secondCategoryRecord.parentCategory
      ? secondCategoryRecord.parentCategory[0]
      : secondCategoryRecord

    let firstScore = publicCategories.indexOf(
      publicCategories.find(c => c.name === firstParent.name)
    )

    let secondScore = publicCategories.indexOf(
      publicCategories.find(c => c.name === secondParent.name)
    )

    // If the artist is categorized in a subcategory, find the index of that subcategory in its
    // parent, and use that for our second pass of weighting
    if (
      firstCategoryRecord.parentCategory &&
      firstCategoryRecord.parentCategory.length
    ) {
      const indexOfSubcategory = publicCategories[
        firstScore
      ].subcategories.indexOf(firstCategoryRecord)

      // Starting at 0.1, add decimal-based weighting based on the position of the given subcategory in its
      // parent category's `subcategories` array. Starting at 0.1 allows artists categorized into the parent
      // category to be scored as 0 and come before subcategorized artists.
      firstScore += 0.1 + indexOfSubcategory / 10
    }

    if (
      secondCategoryRecord.parentCategory &&
      secondCategoryRecord.parentCategory.length
    ) {
      const indexOfSubcategory = publicCategories[
        secondScore
      ].subcategories.indexOf(secondCategoryRecord)

      secondScore += 0.1 + indexOfSubcategory / 10
    } */

    // These artists are in the same category/subcategory, final check for alphabetical-by-name sort
    // using the optional `sortKey` value if provided.
    const firstSortKey = first.node.sortKey
      ? first.node.sortKey
      : first.node.name
    const secondSortKey = second.node.sortKey
      ? second.node.sortKey
      : second.node.name

    return firstSortKey < secondSortKey ? -1 : 1
  })

  function handleGridIconClick() {
    if (showGridView) {
      return
    }

    setShowGridView(!showGridView)
    setShowListView(!setShowListView)
  }

  function handleListIconClick() {
    if (showListView) {
      return
    }

    setShowGridView(!showGridView)
    setShowListView(!showListView)
  }

  return (
    <Layout headerTheme="dark" useRosterFiltering>
      <SEO title="Columbia Artists - Roster" path="/roster" />
      {paramsParsed && (
        <RosterWrapper>
          <IconWrapper>
            <IconButton onClick={handleGridIconClick}>
              <StyledGridIcon active={showGridView} />
            </IconButton>

            <IconButton onClick={handleListIconClick}>
              <StyledListIcon active={showListView} />
            </IconButton>
          </IconWrapper>
          <GridView show={showGridView}>
            <GridBoxContainer>
              {sortedArtists.map(entry => {
                const mainTitle = entry.node.name

                const category = entry.node.categories
                  .map((category, i) =>
                    category.keepPlural
                      ? category.name
                      : singularize(category.name)
                  )
                  .join(", ")

                const artistLink = `/artists/${entry.node.slug}`

                return (
                  <GridBox>
                    <Link to={artistLink}>
                      {entry.node.thumbnailImage && (
                        <GridImage
                          alt={mainTitle}
                          fluid={entry.node.thumbnailImage.fluid}
                        />
                      )}
                      <div>
                        <GridContent>
                          <GridTitleStyle>
                            {mainTitle} <span>{category}</span>
                          </GridTitleStyle>
                        </GridContent>
                      </div>
                    </Link>
                  </GridBox>
                )
              })}

              {flexFix(sortedArtists)}
            </GridBoxContainer>
          </GridView>

          <ListView show={showListView}>
            {sortedArtists.map(entry => {
              const mainTitle = entry.node.name
              const artistLink = `/artists/${entry.node.slug}`

              const category = entry.node.categories
                .map((category, i) =>
                  category.keepPlural
                    ? category.name
                    : singularize(category.name)
                )
                .join(", ")

              return (
                <div key={artistLink}>
                  <Link to={artistLink}>
                    {mainTitle}
                    {""} <em>{category}</em>
                  </Link>
                </div>
              )
            })}
          </ListView>
        </RosterWrapper>
      )}
    </Layout>
  )
}

export default Roster

export const pageQuery = graphql`
  {
    allContentfulArtist(
      filter: { categories: { elemMatch: { id: { ne: null } } } }
    ) {
      edges {
        node {
          name
          slug
          sortKey
          thumbnailImage {
            fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          categories {
            name
            parentCategory: category {
              name
            }
            keepPlural
          }
        }
      }
    }

    allContentfulGlobal {
      edges {
        node {
          publicCategories {
            name
            subcategories {
              name
              parentCategory: category {
                name
              }
            }
          }
        }
      }
    }
  }
`
